import React from 'react';
import '../styles/common.scss'


// eslint-disable-next-line
const LayerPopup = ({ text, onClose, passKeyLogin }) => {

    return (
        <div className={'layerpopup-container'} >
            <div className='layerpopup-wrap'>
                {passKeyLogin ? (
                    <>
                        <h1 className='title'>환영합니다.</h1>
                        <p className='summary'>이미 패스키가 등록되었습니다. <br />패스키로 로그인해 주세요.</p>
                    </>
                )
                    :
                    (
                        <>
                            <h1 className='title'>알림</h1>
                            <p>{text}</p>
                        </>
                    )
                }
                < button onClick={onClose}>
                    확인
                </button>
            </div>
            <div className='popup-bg'></div>
        </div >
    )
}


// {
//     passKeyLogin?(
//             <>
//     <div className='layerpopup-wrap'>
//         <h1 className='title'>Welcome</h1>
//         <p className='summary'>이미 패스키가 등록되었습니다. <br />패스키로 로그인해 주세요.</p>

//     </div>
//             </>
//         ) :
// (
//     <>
//         <div className='layerpopup-wrap'>
//             <p>{text}</p>
//             <button onClick={onClose}>
//                 close
//             </button>
//         </div>
//     </>
// )
//     }
// <div className='popup-bg'></div>
//         </div >
//     )
// };

export default LayerPopup;