import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import LayerPopup from '../components/LayerPopup';
import PasskeyCreateBtn from '../components/PasskeyCreateBtn';
import LogoutBtn from '../components/LogoutBtn';
import QrBtn from '../components/QrBtn';

const RegisterPasskey = () => {
    const locationPathname = location.search
    const [parms, setParms] = useState('')
    const navigate = useNavigate();
    const [errorMessage, setErrorMessage] = useState(null)
    const [activeWindow, setActiveWindow] = useState(false)
    const [userId, setUserId] = useState('')
    const userAgent = window.navigator.userAgent.toLowerCase();

    useEffect(() => {
        setParms(locationPathname)
        const userId = locationPathname.split('&id=')
        console.log('userId', userId)
        setUserId(userId[1])
        if (userAgent.indexOf('windows') > -1) {
            // 윈도우 환경                   
            setActiveWindow(true)

        } else {
            setActiveWindow(false)
        }

    }, [locationPathname])


    const errMsgQrBtn = (e) => {
        setErrorMessage(e)

    }


    return (
        <>
            <div className='passkey-container welcome-container'>
                {/* ?bj = 회원가입페이지에서 넘어온 경우 */}
                {parms === '?bj' ? (
                    <>
                        <h1 className='title'>Welcome</h1>
                        <p className='summary'>가입되었습니다.<br /> 디바이스가 Passkey 인증을 지원합니다.<br />
                            Passkey 등록을 통해 편리하고 보안이 강화된 인증을 수행할 수 있습니다.
                        </p>
                    </>
                ) : (
                    <>
                        <h1 className='title'>Sign In</h1>
                        {parms === '?noRegisterpasskey' ? (
                            <p className='summary'>패스키가 등록 되어있지 않습니다.<br />
                                Passkey 등록을 통해 편리하고 보안이 강화된 인증을 수행할 수 있습니다.
                            </p>
                        ) : (
                            <p className='summary'>디바이스가 Passkey 인증을 지원합니다.<br />
                                Passkey 등록을 통해 편리하고 보안이 강화된 인증을 수행할 수 있습니다.
                            </p>
                        )}

                    </>
                )}
                {/* 이메일인지 로그인인지 분리 */}
                {activeWindow ? (
                    <>
                        <QrBtn type="register" _id={userId} errMsgQrBtn={errMsgQrBtn} />
                    </>
                ) : (
                    <>
                        <PasskeyCreateBtn errMsgQrBtn={errMsgQrBtn} />
                    </>
                )}
                <LogoutBtn />

            </div>

            {errorMessage && <LayerPopup text={errorMessage} onClose={() => setErrorMessage(null)}></LayerPopup>}
        </>
    );
};

export default RegisterPasskey;