import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Login from './pages/Login'
import Join from './pages/Join'
import Welcome from './pages/Welcome'
import RegisterPasskey from './pages/RegisterPasskey';
import EmailRegisterPasskeys from './pages/EmailRegisterPasskeys';
import Qr from './pages/Qr';
import './styles/App.css';
import './styles/common.scss'
const token = sessionStorage.getItem("Authorization");

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/join" element={<Join />} />
        <Route path="/welcome" element={<Welcome />} />
        <Route path="/emailRegisterPasskeys" element={<EmailRegisterPasskeys />} />
        <Route path="/registerpasskey" element={<RegisterPasskey />} />
        <Route path="/qr" element={<Qr />} />
      </Routes>
    </div>

  );
}


export default App;
