import React from 'react';
import '../styles/common.scss'


// eslint-disable-next-line
const LayerPopup2 = ({ onClose, }) => {

    return (
        <div className={'layerpopup-container'} >
            <div className='layerpopup-wrap'>
                <p className='summary'>2차 인증을 선택하신 가입자입니다. <br /> 패스키 등록이 필요합니다.</p>
                < button onClick={onClose}>
                    확인
                </button>
            </div>
            <div className='popup-bg'></div>
        </div >
    )
}




export default LayerPopup2;