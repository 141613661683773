import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import LayerPopup from '../components/LayerPopup';
import LogoutBtn from '../components/LogoutBtn';
import { errorCode } from '../CommonFunction'

const Welcome = () => {
    const locationPathname = location.search
    const [parms, setParms] = useState('')
    const navigate = useNavigate();
    const [errorMessage, setErrorMessage] = useState(null)
    const [userId, setUserId] = useState('')
    const [userPasskeyInfo, setUserPasskeyInfo] = useState('')
    const [email, setEmail] = useState(false)

    useEffect(() => {
        setParms(locationPathname)
        // 가입정보 
        if (!email) {
            axios.get('/webauthn/users/userinfo').then((res) => {
                if (res.data.code !== 0) {
                    // 에러             
                    const message = errorCode(res.data.code)
                    setErrorMessage(message)
                    return
                } else {
                    const regex = /['"]/g;
                    const userId = JSON.stringify(res.data.userInfo.userId)
                    const passKeyInfo = JSON.stringify(res.data.userInfo.credentials[0].id)

                    setUserId(userId.replace(regex, ''))
                    setUserPasskeyInfo(passKeyInfo.replace(regex, ''))


                }
            }).catch(function (err) {
                const code = err.response.data.code
                const message = errorCode(code)
                setErrorMessage(message)
                return;
            })

        } else {
            axios.get('/webauthn2/users/userinfo').then((res) => {
                if (res.data.code !== 0) {
                    // 에러             
                    const message = errorCode(res.data.code)
                    setErrorMessage(message)
                    return
                } else {
                    const regex = /['"]/g;
                    const userId = JSON.stringify(res.data.userInfo.userId)
                    const passKeyInfo = JSON.stringify(res.data.userInfo.credentials[0].id)

                    setUserId(userId.replace(regex, ''))
                    setUserPasskeyInfo(passKeyInfo.replace(regex, ''))


                }
            }).catch(function (err) {
                const code = err.response.data.code
                const message = errorCode(code)
                setErrorMessage(message)
                return;
            })

            console.log('userID', userId)
        }

    }, [locationPathname])


    return (
        <>
            <div className='passkey-container welcome-container'>
                {/* ?passkey = 패스키로 로그인 한 경우 */}
                <h1 className='title'>Welcome</h1>
                {parms === '?passkey' ? (
                    <p className='summary'>Passkey를 이용하여 정상적으로 로그인 되었습니다.</p>
                ) : (
                    <>
                        {parms === '?qr' ? (
                            <>
                                <p className='summary'>QR 코드를 이용하여 정상적으로 등록되었습니다.
                                    <br />
                                    다음부터 QR 코드를 이용하여 로그인 할 수 있습니다.
                                </p>

                            </>
                        ) : (
                            <p className='summary'>Passkey가 정상적으로 등록되었습니다.
                                <br />
                                다음부터 Passkey를 이용하여 로그인 할 수 있습니다.
                            </p>
                        )}
                    </>
                )
                }

                {userId ? (
                    <>
                        <div className='userinfo'>
                            <p>[사용자 가입정보]</p>
                            <p>userId : <span>{userId}</span></p>
                            <p>passkey : <span>{userPasskeyInfo}</span></p>
                            {/* passkey: {userInfo.credentials[0].id} */}
                        </div>
                    </>
                ) : ('')}
                <LogoutBtn />

            </div>
            {errorMessage && <LayerPopup text={errorMessage} onClose={() => setErrorMessage(null)}></LayerPopup>}
        </>
    );
};

export default Welcome;