import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import LayerPopup from '../components/LayerPopup';
import { errorCode } from '../CommonFunction'

const PasskeyCreateBtn = ({ errMsgQrBtn }) => {
    const [errorMessage, setErrorMessage] = useState(null)
    const navigate = useNavigate();
    function base64js(exports) {
        var lookup = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/'
        var Arr = (typeof Uint8Array !== 'undefined')
            ? Uint8Array
            : Array

        var PLUS = '+'.charCodeAt(0)
        var SLASH = '/'.charCodeAt(0)
        var NUMBER = '0'.charCodeAt(0)
        var LOWER = 'a'.charCodeAt(0)
        var UPPER = 'A'.charCodeAt(0)
        var PLUS_URL_SAFE = '-'.charCodeAt(0)
        var SLASH_URL_SAFE = '_'.charCodeAt(0)

        function decode(elt) {
            var code = elt.charCodeAt(0)
            if (code === PLUS || code === PLUS_URL_SAFE) return 62 // '+'
            if (code === SLASH || code === SLASH_URL_SAFE) return 63 // '/'
            if (code < NUMBER) return -1 // no match
            if (code < NUMBER + 10) return code - NUMBER + 26 + 26
            if (code < UPPER + 26) return code - UPPER
            if (code < LOWER + 26) return code - LOWER + 26
        }
        function b64ToByteArray(b64) {
            var i, j, l, tmp, placeHolders, arr

            if (b64.length % 4 > 0) {
                throw new Error('Invalid string. Length must be a multiple of 4')
            }

            // the number of equal signs (place holders)
            // if there are two placeholders, than the two characters before it
            // represent one byte
            // if there is only one, then the three characters before it represent 2 bytes
            // this is just a cheap hack to not do indexOf twice
            var len = b64.length
            placeHolders = b64.charAt(len - 2) === '=' ? 2 : b64.charAt(len - 1) === '=' ? 1 : 0

            // base64 is 4/3 + up to two characters of the original data
            arr = new Arr(b64.length * 3 / 4 - placeHolders)

            // if there are placeholders, only get up to the last complete 4 chars
            l = placeHolders > 0 ? b64.length - 4 : b64.length

            var L = 0

            function push(v) {
                arr[L++] = v
            }

            for (i = 0, j = 0; i < l; i += 4, j += 3) {
                tmp = (decode(b64.charAt(i)) << 18) | (decode(b64.charAt(i + 1)) << 12) | (decode(b64.charAt(i + 2)) << 6) | decode(b64.charAt(i + 3))
                push((tmp & 0xFF0000) >> 16)
                push((tmp & 0xFF00) >> 8)
                push(tmp & 0xFF)
            }

            if (placeHolders === 2) {
                tmp = (decode(b64.charAt(i)) << 2) | (decode(b64.charAt(i + 1)) >> 4)
                push(tmp & 0xFF)
            } else if (placeHolders === 1) {
                tmp = (decode(b64.charAt(i)) << 10) | (decode(b64.charAt(i + 1)) << 4) | (decode(b64.charAt(i + 2)) >> 2)
                push((tmp >> 8) & 0xFF)
                push(tmp & 0xFF)
            }

            return arr
        }
        function uint8ToBase64(uint8) {
            var i
            var extraBytes = uint8.length % 3 // if we have 1 byte left, pad 2 bytes
            var output = ''
            var temp, length

            function encode(num) {
                return lookup.charAt(num)
            }

            function tripletToBase64(num) {
                return encode(num >> 18 & 0x3F) + encode(num >> 12 & 0x3F) + encode(num >> 6 & 0x3F) + encode(num & 0x3F)
            }

            // go through the array every three bytes, we'll deal with trailing stuff later
            for (i = 0, length = uint8.length - extraBytes; i < length; i += 3) {
                temp = (uint8[i] << 16) + (uint8[i + 1] << 8) + (uint8[i + 2])
                output += tripletToBase64(temp)
            }

            // pad the end with zeros, but make sure to not forget the extra bytes
            switch (extraBytes) {
                case 1:
                    temp = uint8[uint8.length - 1]
                    output += encode(temp >> 2)
                    output += encode((temp << 4) & 0x3F)
                    output += '=='
                    break
                case 2:
                    temp = (uint8[uint8.length - 2] << 8) + (uint8[uint8.length - 1])
                    output += encode(temp >> 10)
                    output += encode((temp >> 4) & 0x3F)
                    output += encode((temp << 2) & 0x3F)
                    output += '='
                    break
                default:
                    break
            }

            return output
        }

        exports.toByteArray = b64ToByteArray
        exports.fromByteArray = uint8ToBase64

    }

    // passKey등록 
    function bufferDecode(value) {
        return Uint8Array.from(atob(
            // eslint-disable-next-line
            value.replace(/\-/g, "+")
                // eslint-disable-next-line
                .replace(/\_/g, "/")), c => c.charCodeAt(0));
    }
    // Encode an ArrayBuffer into a base64 string.
    function bufferEncode(value) {
        let base64 = {}
        base64js(base64)

        return base64.fromByteArray(value)
            .replace(/\+/g, "-")
            .replace(/\//g, "_")
            .replace(/=/g, "");
    }


    function sendRequest(url, data, cb) {
        var xhr = new XMLHttpRequest();
        xhr.open("POST", url, true);
        xhr.withCredentials = true;
        xhr.setRequestHeader('content-type', 'application/json; charset=UTF-8');

        xhr.onreadystatechange = function (e) {
            if (xhr.readyState !== XMLHttpRequest.DONE) return;

            if (xhr.status === 200) {
                cb(JSON.parse(xhr.responseText));
            }
            else {
                console.error(e);
            }
        }

        xhr.send(JSON.stringify(data));
    }


    function getPublicKeyCredentialCreationOptions(userData, callback) {
        var url = '/webauthn/webauthn/create';
        sendRequest(url, userData, callback);
    }

    function registerCredential(attestationResponse, callback) {
        var url = '/webauthn/webauthn/register';
        sendRequest(url, attestationResponse, callback);
    }


    function register(callback) {

        getPublicKeyCredentialCreationOptions({}, function (result) {
            if (result.code != 0) {
                callback(result);
                return;
            }

            result = result.data;
            console.log('result', result)

            if (result.excludeCredentials.length > 0) {
                callback({ code: 1004, message: 'this user already owns the passkey.' })
                return;
            }

            result.challenge = bufferDecode(result.challenge);
            result.user.id = bufferDecode(result.user.id);

            // console.log('result2')
            for (var i = 0; i < result.excludeCredentials.length; i++) {
                result.excludeCredentials[i].id = bufferDecode(result.excludeCredentials[i].id);
            }

            result.authenticatorSelection = {
                //authenticatorAttachment: "cross-platform", //!!! 
                residentKey: "preferred",                  //!!!
                userVerification: "preferred"              //!!!
            };

            result.extensions = {
                credProps: true,
            };

            navigator.credentials.create({
                publicKey: result,
            }).then(function (credential) {

                var attestation = {
                    id: bufferEncode(new Uint8Array(credential.rawId)),
                    response: {
                        clientDataJSON: bufferEncode(new Uint8Array(credential.response.clientDataJSON)),
                        attestationObject: bufferEncode(new Uint8Array(credential.response.attestationObject)),
                    }
                };

                var transports = [];
                if (credential.response.getTransports) transports = credential.response.getTransports();
                var extensions = credential.getClientExtensionResults();

                attestation.transports = transports
                attestation.extensions = extensions;

                registerCredential(attestation, function (result) {
                    if (result.code !== 0) {
                        const message = errorCode(result.code)
                        errMsgQrBtn(message)
                        return
                    } else {
                        navigate("/welcome")
                        callback(result);
                    }

                });

            }).catch(function (error) {
                console.log('error', error)
                errMsgQrBtn('패스키 인증이 취소되었습니다.')
            });
        });
    }


    return (
        <>
            <button className='passkey-register' onClick={() => { register(function () { }) }}>
                <i className="bi bi-key-fill"></i>
                Register Passkey</button>
        </>
    );
};

export default PasskeyCreateBtn;