import React from 'react';

const QrPage = () => {

    function sendRequest(url, data, cb) {
        var xhr = new XMLHttpRequest();
        xhr.open("POST", url, true);
        xhr.withCredentials = true;
        xhr.setRequestHeader('content-type', 'application/json; charset=UTF-8');

        xhr.onreadystatechange = function (e) {
            if (xhr.readyState !== XMLHttpRequest.DONE) return;

            if (xhr.status === 200) {
                cb(JSON.parse(xhr.responseText));
            }
            else {
                console.error(e);
            }
        }

        xhr.send(JSON.stringify(data));
    }

    function writeLog(msg) {
        document.getElementById('txlog').textContent = msg;
    }
    function appendLog(msg, nl) {
        document.getElementById('txlog').textContent += ((nl ? "\n" : "") + msg);
    }

    function getQRToken() {
        return getParam('qrToken');
    }

    function getParam(name) {
        var urlParams = new URLSearchParams(location.search);
        return urlParams.get(name);
    }

    function registerPassKey() {
        var qrToken = getQRToken();

        WebAuthn.registerWithQR(qrToken, function (res) {
            writeLog(JSON.stringify(res, null, 2));

            if (res.code === 0) window.close();
        });
    }

    function signPassKey() {
        var qrToken = getQRToken();

        WebAuthn.authWithQR(qrToken, function (res) {
            writeLog(JSON.stringify(res, null, 2));

            if (res.code === 0) window.close();
        });
    }

    function cancelQR() {
        var qrToken = getQRToken();

        sendRequest('/webauthn/users/cancel-qr-token', { qrToken: qrToken }, function (res) {
            writeLog(JSON.stringify(res, null, 2));

            if (res.code === 0) window.close();
        });
    }

    function retryQR() {

        var type = getParam('type');

        if (type === 'register') registerPassKey();
        else if (type === 'assertion') signPassKey();
    }

    window.addEventListener('load', function (ev) {
        var type = getParam('type');

        if (type === 'register') registerPassKey();
        else if (type === 'assertion') signPassKey();
    });



    return (
        <div className='passkey-container qr-container '>
            Retry  : <button onClick={() => retryQR()}>Retry</button><br />
            Cancel QR Request  : <button onClick={() => cancelQR()}>Cancel</button><br />
            <hr />
            <textarea id="txlog" readonly></textarea>
        </div>
    );
};

export default QrPage;