

import React, { useEffect, useState, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import LayerPopup from '../components/LayerPopup';
import LayerPopup2 from '../components/LayerPopup2';
import PasskeyAuthBtn from '../components/PasskeyAuthBtn';
import PasskeyLogin from '../components/PasskeyLogin';
import { errorCode, QrAuth, QrRegister, passkeyAuth, passkeyRegister } from '../CommonFunction'
import QrBtn from '../components/QrBtn';
import QrBtnEmail from '../components/QrBtnEmail';
import QRCodeStyling from "qr-code-styling";


const qrCode = new QRCodeStyling({
    width: 200,
    height: 200,
    image:
        // "https://upload.wikimedia.org/wikipedia/commons/5/51/Facebook_f_logo_%282019%29.svg",
        "",
    // "../public/assets/img/color-logo.svg",
    dotsOptions: {
        color: "#333",
        type: "rounded"
    },
    imageOptions: {
        crossOrigin: "anonymous",
        margin: 5
    }
});

let twoFaintervalId = undefined;
let intervalId = undefined;

const Login = () => {
    const navigate = useNavigate();
    const [inputs, setInputs] = useState({
        id: '',
        pw: '',
        mail: '',
        authCode: ''
    })
    const [errorMessage, setErrorMessage] = useState(null)
    const [errorMessage2, setErrorMessage2] = useState(null)
    const [nopasskey, setNopasskey] = useState(false)
    const [passKeyLoginActive, setPassKeyLoginActive] = useState(false)
    // 메일용
    const [mailAuthActive, setMailAuthActive] = useState(false)
    // 2차 인증용
    const [twofa, setTwofa] = useState(false)
    const [codeCheckActive, setCodeCheckActive] = useState(false)
    const [activeWindow, setActiveWindow] = useState(false)
    const [loading, setLoading] = useState(false)

    const userAgent = window.navigator.userAgent.toLowerCase();
    const [loadingBtn, setLoadingBtn] = useState(false)
    const { id, pw, mail, authCode } = inputs;
    const [url, setUrl] = useState('/webauthn/users/request-qr-token', { userId: null, type: 'assertion' });
    const ref = useRef(null);
    const [activeQr2Fa, setActiveQr2Fa] = useState(false)


    const mailSubmit = (e) => {
        //메일 주소
        e.preventDefault();

        if (!mail) {
            setErrorMessage('이메일을 입력해주세요')
            return;
        }

        let regExp = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
        if (mail.match(regExp) == null) {
            setErrorMessage('메일 형식을 확인해주세요.')
            return;
        }

        setLoading(true)
        axios.post(`/webauthn2/users/checkemail`, {
            email: mail
        }).then(function (res) {
            if (res.data.code === 1005) {
                // 이메일 등록 x, 이메일로 인증코드 발송
                setErrorMessage('등록된 사용자가 아닙니다. 해당 메일로 인증코드를 보냈습니다.')
                setCodeCheckActive(true)
            } else if (res.data.code === 1003) {
                // 이메일 등록 o, 패스키 등록x 이메일로 인증코드 발송
                setErrorMessage('해당 메일로 인증코드를 보냈습니다.')
                setCodeCheckActive(true)
            } else if (res.data.code === 0) {
                // 이메일로 로그인
                if (res.data.credential) {
                    setPassKeyLoginActive(true)
                    setLoading(false)
                } else {
                    navigate("/emailRegisterPasskeys?bj&id=" + mail)
                }

            } else {
                // 에러 발생
                const message = errorCode(res.data.code)
                setErrorMessage(message)
                setLoading(false)
            }

        })

    }

    const checkCode = (e) => {
        // 인증코드 확인
        e.preventDefault();

        if (!authCode) {
            setErrorMessage('인증번호를 입력해주세요.')
            return;
        }

        axios.post(`/webauthn2/users/checkcode`, {
            email: mail,
            authCode: parseInt(authCode)

        }).then(function (res) {
            if (res.data.code !== 0) {
                const message = errorCode(res.data.code)
                setErrorMessage(message)
                return
            } else {
                navigate("/emailRegisterPasskeys?bj&id=" + mail)
                // 패스키 인증으로 이동? 확인해봐야함
            }


        })
    }


    const submit = (e) => {
        e.preventDefault();

        if (!id) {
            setErrorMessage('아이디를 입력해주세요.')
            return;
        } else if (!pw) {
            setErrorMessage('비밀번호를 입력해주세요.')
            return
        }


        axios.post(`/webauthn/users/signin`, {
            userId: id,
            userPw: pw
        }, { withCredentials: true }).then(function (res) {
            console.log('LoginRes', res)
            if (res.data.code !== 0) {
                // 에러

                if (res.data.code === 1012) {
                    // 2차인증 사용자,  패스키 등록x

                    // 패스키 등록 전 안내 팝업
                    setLoadingBtn(true)
                    setErrorMessage2('LayerPopup2, 패스키 에러메세지')
                    return
                }
                const message = errorCode(res.data.code)
                setErrorMessage(message)
                return
            } else {
                if (res.data.credential) {
                    // navigate("/passkeylogin")

                    if (res.data.use2FA) {
                        // 2차 인증 사용자, 패스키 등록 완

                        setLoadingBtn(true)

                        if (activeWindow) {
                            //윈도우일시 qr 제공
                            axios.post('/webauthn/users/request-qr-token', {/* userId: _id, */ type: 'assertion' }).then(res => {
                                if (res.data.code !== 0) {
                                    const message = errorCode(res.data.code)
                                    setErrorMessage(message)
                                    return;
                                }
                                var qrToken = res.data.qrToken;
                                setUrl(location.origin + '/qrIndex.html?qrToken=' + qrToken + '&type=assertion');
                                //location.origin
                                // https://192.168.0.142

                                // qrCode.append(ref.current);
                                setActiveQr2Fa(true)


                                intervalId = setInterval(function (qrToken) {
                                    axios.post('/webauthn/users/check-qr-token', { qrToken: qrToken }).then(res => {
                                        console.log('check-qr-token.data', res.data)
                                        switch (res.data.status) {
                                            case 'requested':
                                                return;
                                            case 'completed':
                                                clearInterval(intervalId);
                                                navigate("/welcome?passkey")
                                                break;
                                            case 'canceled':
                                                clearInterval(intervalId);
                                                setActiveQr2Fa(false)
                                                setLoadingBtn(false)
                                                setErrorMessage('취소 되었습니다')
                                                break;
                                            default:
                                                clearInterval(intervalId);
                                                setActiveQr2Fa(false)
                                                setErrorMessage('error')
                                                break;
                                        }
                                    })
                                }, 3000, qrToken);

                            })

                        } else {
                            passkeyAuth((result) => {
                                setLoadingBtn(false)
                                if (result === 'error') {
                                    setErrorMessage('패스키 인증이 취소되었습니다.')

                                } else if (result.code !== 0) {
                                    const message = errorCode(result.code)
                                    setErrorMessage(message)
                                    return
                                } else {
                                    navigate("/welcome?passkey")
                                }

                            });
                        }
                    } else {
                        //패스키로 로그인하세요 알람
                        // setPassKeyLoginActive(true)
                        // setLoadingBtn(true)
                        setErrorMessage('2차 인증 사용자가 아닙니다.')
                        return
                    }

                } else {
                    navigate("/registerpasskey?bj&id=" + id)
                }
            }


        }).catch(function (err) {
            console.log('err', err)
            // const code = err.response.data.code
            // const message = errorCode(code)
            // setErrorMessage(message)
            return;
        })

    };



    const onChange = (e) => {

        const { value, name } = e.target;
        setInputs({
            ...inputs,
            [name]: value
        })
    }



    function detectWebAuthnSupport() {
        if (window.PublicKeyCredential === undefined ||
            typeof window.PublicKeyCredential !== "function") {
            var errorMessage = "passKey를 지원하지않는 브라우저입니다."
            if (window.location.protocol === "http:" && (window.location.hostname !== "localhost" && window.location.hostname !== "127.0.0.1")) {
                errorMessage = "WebAuthn only supports secure connections. For testing over HTTP, you can use the origin \"localhost\"."
            }
            setErrorMessage(errorMessage);
            setNopasskey(true)
            return false;
        }
        return true;
    }

    const closePopup = () => {
        setErrorMessage(null)
        setPassKeyLoginActive(false)
        setLoadingBtn(false)
    }

    const closePopup2 = () => {
        setErrorMessage2(null)
        setLoadingBtn(false)
        if (activeWindow) {
            // 윈도우 환경일때, qr등록
            // QrRegister('test0417-01', (result) => {
            //     qrCode.append(ref.current);
            //     console.log('qrResister', result)
            // })

            axios.post('/webauthn/users/request-qr-token', { userId: id, type: 'register' }).then(res => {
                console.log('register', res, 'id', id)
                if (res.data.code !== 0) {
                    const message = errorCode(res.data.code)
                    setErrorMessage(message)
                    return;
                }
                var qrToken = res.data.qrToken;
                setUrl(location.origin + '/qrIndex.html?qrToken=' + qrToken + '&type=register');
                //location.origin

                setActiveQr2Fa(true)

                // 2차 인증용
                twoFaintervalId = setInterval(function (qrToken) {
                    axios.post('/webauthn/users/check-qr-token', { qrToken: qrToken }).then(res => {
                        console.log('2차인증용,', res)
                        switch (res.data.status) {
                            case 'requested':
                                return;
                            case 'completed':
                                clearInterval(intervalId);
                                navigate("/welcome?qr")
                                break;
                            case 'canceled':
                                clearInterval(intervalId);
                                setActiveQr2Fa(false)
                                setLoadingBtn(true)
                                setErrorMessage('취소 되었습니다')
                                break;
                            default:
                                clearInterval(intervalId);
                                setActiveQr2Fa(false)
                                setErrorMessage('error')
                                break;
                        }
                    })
                }, 3000, qrToken);

            })

        } else {
            // 패스키 등록
            passkeyRegister((result) => {
                if (result === 'error') {
                    setErrorMessage('패스키 인증이 취소되었습니다.')
                } else if (result.code !== 0) {
                    const message = errorCode(result.code)
                    setErrorMessage(message)
                    return
                } else {
                    navigate("/welcome")
                }

            });
        }


    }
    const errMsgQrBtn = (e) => {
        setErrorMessage(e)

    }

    const cancleQr2Fa = () => {
        setActiveQr2Fa(false);
        clearInterval(twoFaintervalId)
        clearInterval(intervalId)
        setLoadingBtn(false)
    }


    useEffect(() => {

        detectWebAuthnSupport()
        //qr체크시
        if (userAgent.indexOf('windows') > -1) {
            // 윈도우 환경
            setActiveWindow(true)
            qrCode.update({
                data: url
            });
            qrCode.append(ref.current);
        } else {
            setActiveWindow(false)
        }

    }, [url])


    return (
        < >
            {mailAuthActive ? (
                <>
                    {/* 메일 일 때  */}
                    <>
                        <div className='passkey-container login-container '>
                            {/* <h1 className='title'>로그인</h1> */}
                            {/* <div className='login-sub'>
                                <p>패스키로 로그인하기 위해 메일주소를 입력해주세요.</p>
                                <p>아직 등록되지 않은 메일주소인 경우 입력하신 메일주소로 인증코드가 발송됩니다.</p>
                                <p><span>인증코드를 확인후 패스키 등록이 가능합니다. <br />(브라우저가 패스키를 지원해야 합니다.)</span></p>
                            </div> */}
                            <form>

                                {!codeCheckActive ? (
                                    <>
                                        <input type="text" name="mail" placeholder={'메일 주소를 입력해주세요'} onChange={onChange} value={mail} autoComplete='off'></input>
                                        <button className='login' onClick={(e) => mailSubmit(e)} disabled={loading}>
                                            로그인
                                        </button>
                                    </>
                                ) : (
                                    <>
                                        <input type="text" name="authCode" placeholder={'코드를 입력해주세요'} onChange={onChange} value={authCode} autoComplete='off'></input>
                                        <button className='check-code' onClick={(e) => checkCode(e)}>코드 확인</button>
                                    </>
                                )}

                            </form>

                            <div className="or-line"><span></span><p>OR</p><span></span></div>
                            {activeWindow ? (
                                <><QrBtnEmail type='assertion' _id='' errMsgQrBtn={errMsgQrBtn} /></>
                            ) : (
                                <PasskeyAuthBtn errMsgQrBtn={errMsgQrBtn} />
                            )}
                        </div>
                        {(errorMessage || passKeyLoginActive) && <LayerPopup text={errorMessage} onClose={() => closePopup()} passKeyLogin={passKeyLoginActive}></LayerPopup>}
                    </>
                </>
            ) : (
                // 아이디&비밀번호 형태
                <>
                    <div className='passkey-container login-container '>
                        {/* <h1 className='title'>로그인</h1> */}

                        {/* {twofa ? (<>
                            <div className='login-sub'>
                                <p>아이디/비밀번호에 2차인증 용도로 패스키를 사용할 수 있습니다. </p>
                                <p>비밀번호에 패스키를 추가로 등록하여 2차인증을 진행합니다.</p>
                                <p>(브라우저가 패스키를 지원해야 합니다.)</p>
                            </div>
                        </>) : (<>
                            <div className='login-sub'>
                                <p>아이디/비밀번호를 이용한 로그인보다 패스키가 더 안전하고 편리합니다.</p>
                                <p>패스키를 등록하면 더 이상 비밀번호를 기억할 필요가 없습니다.</p>
                                <p><span>아이디/비밀번호 방식에서 패스키로의 전환을 경험해보세요.<br />(브라우저가 패스키를 지원해야 합니다.)</span></p>
                            </div>
                        </>)} */}



                        {activeQr2Fa ? (
                            <>
                                <h1 className='title'>패스키 인증</h1>
                                {/* <div id="qrcode"></div> */}
                                <div className='qr-container' ref={ref} ></div>
                                <button className='cancle' onClick={() => cancleQr2Fa()}>취소</button>
                            </>
                        ) : (
                            <>
                                <form>
                                    <div className='input-wrap'>
                                        <input type="text" name="id" placeholder={'아이디를 입력해주세요.'} onChange={onChange} value={id} autoComplete='off' />
                                        <input type="password" name="pw" placeholder={'비밀번호를 입력해주세요.'} onChange={onChange} value={pw} autoComplete='off' />
                                    </div>
                                    <button className='login' disabled={loadingBtn} onClick={(e) => submit(e)}>
                                        로그인
                                    </button>
                                </form>
                                {twofa ? ('') : (<>

                                    <div className="or-line"><span></span><p>OR</p><span></span></div>

                                    {
                                        activeWindow ? (
                                            <QrBtn type='assertion' _id='' errMsgQrBtn={errMsgQrBtn} />
                                        ) : (
                                            <PasskeyAuthBtn errMsgQrBtn={errMsgQrBtn} />
                                        )
                                    }

                                </>)}
                                <p >아이디가 없으시나요? <Link to="/join">사용자 등록하기</Link></p>
                            </>
                        )
                        }



                    </div >
                    {(errorMessage || passKeyLoginActive) && <LayerPopup text={errorMessage} onClose={() => closePopup()} passKeyLogin={passKeyLoginActive}></LayerPopup>}
                    {(errorMessage2) && <LayerPopup2 onClose={() => closePopup2()} ></LayerPopup2>}
                </>
            )
            }

        </>

    );
};

export default Login;
