import React, { useEffect, useState, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import LayerPopup from './LayerPopup';
import QRCodeStyling from "qr-code-styling";
import axios from 'axios';
import { errorCode } from '../CommonFunction'


const qrCode = new QRCodeStyling({
    width: 200,
    height: 200,
    image:
        // "https://upload.wikimedia.org/wikipedia/commons/5/51/Facebook_f_logo_%282019%29.svg",
        "",
    // "../public/assets/img/color-logo.svg",
    dotsOptions: {
        color: "#333",
        type: "rounded"
    },
    imageOptions: {
        crossOrigin: "anonymous",
        margin: 5
    }
});

const QrBtnEmail = ({ type, _id, errMsgQrBtn }) => {

    const navigate = useNavigate();
    const [url, setUrl] = useState('/webauthn/users/request-qr-token', { userId: null, type: 'assertion' });
    const ref = useRef(null);
    const [errorMessage, setErrorMessage] = useState(null)
    const [activeQr, setActiveQr] = useState(false)

    function qrOpen(type) {

        ///* userId: _id, */ type: 'assertion'
        setActiveQr(true)
        console.log('userId', _id)

        if (type === 'register') {
            // 등록일때, 
            axios.post('/webauthn2/users/request-qr-token', { userId: _id, type: 'register' }).then(res => {
                if (res.data.code !== 0) {
                    const message = errorCode(res.data.code)
                    errMsgQrBtn(message)
                    // setErrorMessage(message)
                    setActiveQr(false)
                    return;
                }
                var qrToken = res.data.qrToken;
                setUrl(location.origin + '/qrIndex2.html?qrToken=' + qrToken + '&type=register');
                //location.origin

                qrCode.append(ref.current);


                const intervalId = setInterval(function (qrToken) {
                    axios.post('/webauthn2/users/check-qr-token', { qrToken: qrToken }).then(res => {
                        switch (res.data.status) {
                            case 'requested':
                                return;
                            case 'completed':
                                clearInterval(intervalId);
                                navigate("/welcome?qr")
                                break;
                            case 'canceled':
                                clearInterval(intervalId);
                                setActiveQr(false)
                                errMsgQrBtn('취소 되었습니다')
                                // setErrorMessage('취소 되었습니다')
                                break;
                            default:
                                clearInterval(intervalId);
                                setActiveQr(false)
                                errMsgQrBtn('error')
                                // setErrorMessage('error')
                                break;
                        }
                    })
                }, 3000, qrToken);

            })
        } else {

            axios.post('/webauthn2/users/request-qr-token', {/* userId: _id, */ type: 'assertion' }).then(res => {
                if (res.data.code !== 0) {
                    const message = errorCode(res.data.code)
                    errMsgQrBtn(message)
                    // setErrorMessage(message)
                    return;
                }

                var qrToken = res.data.qrToken;
                setUrl(location.origin + '/qrIndex2.html?qrToken=' + qrToken + '&type=assertion');
                //location.origin

                qrCode.append(ref.current);


                const intervalId = setInterval(function (qrToken) {
                    axios.post('/webauthn2/users/check-qr-token', { qrToken: qrToken }).then(res => {
                        switch (res.data.status) {
                            case 'requested':
                                return;
                            case 'completed':
                                clearInterval(intervalId);
                                navigate("/welcome?passkey")
                                break;
                            case 'canceled':
                                clearInterval(intervalId);
                                setActiveQr(false)
                                errMsgQrBtn('취소 되었습니다')
                                // setErrorMessage('취소 되었습니다')
                                break;
                            default:
                                clearInterval(intervalId);
                                setActiveQr(false)
                                errMsgQrBtn('error')
                                // setErrorMessage('error')
                                break;
                        }
                    })
                }, 3000, qrToken);

            })
        }



    }


    useEffect(() => {
        // if (type === 'register') {
        //     //type: 'register' - 등록, 'assertion' - 로그인
        //     setUrl('/webauthn/users/request-qr-token', { userId: '', type: 'register' })
        // } else {
        //     setUrl('/webauthn/users/request-qr-token', { userId: null, type: 'assertion' })
        // }

        qrCode.update({
            data: url
        });
    }, [url]);

    return (
        <>

            {activeQr ? (
                <>
                    <div id="qrcode"></div>
                    <div className='qr-container' ref={ref} />
                </>
            ) : (
                <>
                    <button className='passkey-login' onClick={() => qrOpen(type)}>passkey</button>
                </>
            )}
        </>
    );
};

export default QrBtnEmail;