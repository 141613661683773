import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import LayerPopup from '../components/LayerPopup';
import { errorCode } from '../CommonFunction'

const LogoutBtn = () => {
    const navigate = useNavigate();
    const [errorMessage, setErrorMessage] = useState(null)
    const logout = () => {
        // mail 땐 webauth2로 보내기 
        axios.post(`/webauthn/users/signout`, { withCredentials: true }).then(function (res) {

            if (res.data.code !== 0) {
                const message = errorCode(res.data.code)
                setErrorMessage(message)
                return
            } else {
                navigate("/")
            }

        }).catch(function (err) {
            setErrorMessage('취소 되었습니다.')
            return;
        })
    }

    return (
        <>
            <button className='logout' onClick={() => { logout() }}>로그아웃</button>
            {errorMessage && <LayerPopup text={errorMessage} onClose={() => setErrorMessage(null)}></LayerPopup>}
        </>
    );
};

export default LogoutBtn;