import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import LayerPopup from '../components/LayerPopup';
import { errorCode } from '../CommonFunction'
import { passkeyRegister } from '../CommonFunction'
import QRCodeStyling from "qr-code-styling";

const qrCode = new QRCodeStyling({
    width: 200,
    height: 200,
    image:
        // "https://upload.wikimedia.org/wikipedia/commons/5/51/Facebook_f_logo_%282019%29.svg",
        "",
    // "../public/assets/img/color-logo.svg",
    dotsOptions: {
        color: "#333",
        type: "rounded"
    },
    imageOptions: {
        crossOrigin: "anonymous",
        margin: 5
    }
});

const Join = () => {
    const locationPathname = location.search
    const navigate = useNavigate();
    const [inputs, setInputs] = useState({
        id: '',
        pw: '',
        confirmPw: '',
        mail: ''
    })
    const { id, pw, confirmPw, mail } = inputs;
    const [mailAuthActive, setMailAuthActive] = useState(false)
    const [errorMessage, setErrorMessage] = useState(null)
    const [twoCheck, setTwoCheck] = useState(false)
    const [checked, setChecked] = useState(false)
    const userAgent = window.navigator.userAgent.toLowerCase();
    const [activeWindow, setActiveWindow] = useState(false)
    const [activeQr2Fa, setActiveQr2Fa] = useState(false)
    const [url, setUrl] = useState('/webauthn/users/request-qr-token', { userId: null, type: 'assertion' });
    const ref = useRef(null);


    const onChange = (e) => {
        const { value, name } = e.target;
        setInputs({
            ...inputs,
            [name]: value
        })
    }

    const checkboxOnchange = (e) => {
        setChecked(e.target.checked)
    }

    const register = () => {
        if (!id) {
            setErrorMessage('아이디를 입력해주세요.')
            return
        } else if (!pw) {
            setErrorMessage('비밀번호를 입력해주세요.')
            return
        } else if (!confirmPw) {
            setErrorMessage('비밀번호 확인란을 입력해주세요.')
            return
        } else if (pw !== confirmPw) {
            setErrorMessage('비밀번호가 같지않습니다.')
            return
        }

        if (id.length < 4 || pw.length < 4) {
            setErrorMessage('아이디와 비밀번호는 4글자 이상으로 입력 가능합니다.')
            return
        }

        if (checked) {
            // 2차인증 사용자
            axios.post(`/webauthn/users/signup-2fa`, {
                userId: id,
                userPw: pw
            }, { withCredentials: true }).then(function (res) {
                // console.log('2차 인증자 회원가입 res', res)
                if (res.data.code !== 0) {
                    if (res.data.code === 1012) {
                        // 윈도우일 경우 qr로 인증 
                        if (activeWindow) {
                            // 윈도우 환경일때, qr등록
                            // QrRegister('test0417-01', (result) => {
                            //     qrCode.append(ref.current);
                            //     console.log('qrResister', result)
                            // })

                            axios.post('/webauthn/users/request-qr-token', { userId: id, type: 'register' }).then(res => {
                                // console.log('register', res, 'id', id)
                                if (res.data.code !== 0) {
                                    const message = errorCode(res.data.code)
                                    setErrorMessage(message)
                                    return;
                                }
                                var qrToken = res.data.qrToken;
                                setUrl(location.origin + '/qrIndex.html?qrToken=' + qrToken + '&type=register');
                                //location.origin

                                setActiveQr2Fa(true)


                                const intervalId = setInterval(function (qrToken) {
                                    axios.post('/webauthn/users/check-qr-token', { qrToken: qrToken }).then(res => {
                                        switch (res.data.status) {
                                            case 'requested':
                                                return;
                                            case 'completed':
                                                clearInterval(intervalId);
                                                navigate("/welcome?qr")
                                                break;
                                            case 'canceled':
                                                clearInterval(intervalId);
                                                setActiveQr2Fa(false)
                                                setLoadingBtn(true)
                                                setErrorMessage('취소 되었습니다')
                                                break;
                                            default:
                                                clearInterval(intervalId);
                                                setActiveQr2Fa(false)
                                                setErrorMessage('error')
                                                break;
                                        }
                                    })
                                }, 3000, qrToken);

                            })

                        } else {
                            passkeyRegister((result) => {
                                // console.log('joinError', result)
                                if (result === 'error') {
                                    // console.log('r')
                                    setErrorMessage('패스키 인증이 취소되었습니다. 패스키를 꼭 등록해주세요.')
                                    navigate("/registerPasskey?noRegisterpasskey")
                                    return
                                } else if (result.code !== 0) {
                                    const message = errorCode(result.code)
                                    setErrorMessage(message)
                                    return
                                } else {
                                    navigate("/welcome")
                                }

                            });
                        }
                        return
                    }
                    const message = errorCode(res.data.code)
                    setErrorMessage(message)
                    return
                }
                else {
                    navigate("/registerpasskey?bj&id=" + id)
                }
            }).catch(function (err) {
                const code = err.response.data.code
                const message = errorCode(code)
                setErrorMessage(message)
                return;
            })
        } else {

            axios.post(`/webauthn/users/signup`, {
                userId: id,
                userPw: pw
            }, { withCredentials: true }).then(function (res) {
                if (res.data.code) {
                    const message = errorCode(res.data.code)
                    setErrorMessage(message)
                    return
                } else {
                    navigate("/registerpasskey?bj&id=" + id)
                }
            }).catch(function (err) {
                const code = err.response.data.code
                const message = errorCode(code)
                setErrorMessage(message)
                return;
            })
        }

    }

    const mailRegister = () => {
        axios.post(`/webauthn2/users/checkemail`, {
            userId: mail
        }).then(function (res) {
            // console.log('res', res)
            if (res.data.code !== 0) {
                const message = errorCode(res.data.code)
                setErrorMessage(message)
            }

            // else {
            //     // 메일 인증코드 확인
            //     axios.post(`/webauthn/users/checkcode`, {
            //         authCode: authCode
            //     }).then(function (res) {

            //     })
            // }
        })
    }

    const goback = () => {
        navigate("/")
    }

    function detectWebAuthnSupport() {
        if (window.PublicKeyCredential === undefined ||
            typeof window.PublicKeyCredential !== "function") {
            var errorMessage = "passKey를 지원하지않는 브라우저입니다."
            if (window.location.protocol === "http:" && (window.location.hostname !== "localhost" && window.location.hostname !== "127.0.0.1")) {
                errorMessage = "WebAuthn only supports secure connections. For testing over HTTP, you can use the origin \"localhost\"."
            }
            setErrorMessage(errorMessage);
            setNopasskey(true)
            return false;
        }
        return true;
    }

    useEffect(() => {

        detectWebAuthnSupport()
        //qr체크시
        if (userAgent.indexOf('windows') > -1) {
            // 윈도우 환경                   
            setActiveWindow(true)
            qrCode.update({
                data: url
            });
            qrCode.append(ref.current);
        } else {
            setActiveWindow(false)
        }

    }, [url])


    return (
        <>
            <div className='passkey-container join-container'>
                <h1 className='title'>사용자 등록</h1>

                {checked ? (
                    <>
                        {/* 2차인증 */}
                        <div className='join-sub'>
                            {/* <p>아이디/비밀번호를 등록 한 후 2차인증용으로 패스키를 등록합니다.</p> */}
                            <p><span>실제 사용하는 아이디/비밀번호가 아닌 임의의 아이디/비밀번호를 입력하시기 바랍니다.</span></p>
                        </div>
                    </>
                ) : (
                    <>
                        <div className='join-sub'>
                            {/* <p>기존 방식인 아이디/비밀번호와 패스키와의 차이를 비교하기 위해 먼저 아이디/비밀번호를 등록합니다.</p> */}
                            <p><span>실제 사용하는 아이디/비밀번호가 아닌 임의의 아이디/비밀번호를 입력하시기 바랍니다.</span></p>
                            {/* <p>브라우저가 패스키를 지원하는 경우, 다음 페이지에서 패스키를 등록합니다. 패스키를 등록하면 비밀번호는 더 이상 필요하지 않습니다.</p> */}
                        </div>
                    </>
                )}

                {mailAuthActive ? (
                    <>
                        <div className='input-wrap join-inputs'>
                            <div>
                                <label>이메일</label>
                                <input type="text" name="mail" onChange={onChange} value={mail} autoComplete="off" ></input>
                            </div>
                        </div>
                        <button className='register' onClick={mailRegister}>등록</button>
                    </>
                ) : (
                    <>
                        {activeQr2Fa ? (
                            <>
                                <div className='qr-container' ref={ref} ></div>
                            </>
                        ) : (
                            <>
                                <div className='input-wrap join-inputs'>
                                    <div>
                                        <label>아이디</label>
                                        <input type="text" name="id" onChange={onChange} value={id} autoComplete="off" ></input>
                                    </div>
                                    <div>
                                        <label>비밀번호</label>
                                        <input type="password" name="pw" onChange={onChange} value={pw} autoComplete="off"></input>
                                    </div>
                                    <div>
                                        <label>비밀번호확인</label>
                                        <input type="password" name="confirmPw" onChange={onChange} value={confirmPw} autoComplete="off"></input>
                                    </div>
                                    {/* {twoCheck ? (
                                    <>
                                        <label><input type='checkbox' checked={checked} onChange={checkboxOnchange}></input>2차 인증</label>
                                    </>
                                ) : ('')} */}
                                </div>

                                <button className='register' onClick={register}>등록</button>
                            </>
                        )}

                    </>
                )}

                <button className='go-back' onClick={goback}>뒤로가기</button>

            </div>
            {errorMessage && <LayerPopup text={errorMessage} onClose={() => setErrorMessage(null)}></LayerPopup>}
        </>
    );
};

export default Join;